// src/components/Program.js

import React, { useEffect, useState, useRef } from 'react';
import { useTheme } from '@emotion/react';
import MKTypography from 'DS/components/MKTypography';
import { Box, Grid, useMediaQuery } from '@mui/material';
import HoverCard from '../components/HoverCard';
import bannerA from 'assets/images/banners/banner-A.jpg';
import bannerG from 'assets/images/banners/banner-G.jpg';
import bannerI from 'assets/images/banners/banner-I.jpg';
import bannerQ from 'assets/images/banners/banner-Q.jpg';

// Hook personalizado para detectar si un elemento está en vista
function useInView(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin: '0px',
        threshold: 0.1,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  return isIntersecting;
}

function Program() {
  const theme = useTheme();
  const isXs = useMediaQuery('(max-width: 720px)');

  // Referencias a cada tarjeta
  const cardRefs = [useRef(), useRef(), useRef()];
  // Estados para saber si cada tarjeta está en vista
  const inViewStates = cardRefs.map((ref) => useInView(ref));

  return (
    <Box
      sx={{
        m: { xs: 2, sm: 3, md: 5 },
        overflowX: 'hidden', // Evita el scroll horizontal debido a los transform
      }}
    >
      {/* Título */}
      <MKTypography
        variant="h4"
        align="start"
        color="text"
        sx={{
          fontWeight: 400,
          letterSpacing: 1,
          marginBottom: 4,
        }}
      >
        Take a look at our <b>programs</b>
      </MKTypography>

      {/* Contenedor de las Tarjetas */}
      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        {/* Tarjeta 1 */}
        <Grid
          item
          ref={cardRefs[0]}
          sx={{
            transform: inViewStates[0] ? 'translateX(0)' : 'translateX(100px)',
            opacity: inViewStates[0] ? 1 : 0,
            transition: 'transform 1s ease-out, opacity 1s ease-out',
          }}
        >
          <HoverCard
            title="Incubation"
            description="4 weeks, remote program designed for startups shaping their MVP."
            backgroundColor="#4D96FF" // Azul vibrante
            image={bannerQ}
          />
        </Grid>

        {/* Tarjeta 2 */}
        <Grid
          item
          ref={cardRefs[1]}
          sx={{
            transform: inViewStates[1] ? 'translateX(0)' : 'translateX(100px)',
            opacity: inViewStates[1] ? 1 : 0,
            transition: 'transform 1s ease-out 0.3s, opacity 1s ease-out 0.3s',
          }}
        >
          <HoverCard
            title="Acceleration"
            description="12-week, on-site program in Buenos Aires, designed for startups looking to find Product Market Fit."
            cta="EXPLORE"
            backgroundColor="#6BCB77" // Verde vibrante
            image={bannerA}
          />
        </Grid>

        {/* Tarjeta 3 */}
        <Grid
          item
          ref={cardRefs[2]}
          sx={{
            transform: inViewStates[2] ? 'translateX(0)' : 'translateX(100px)',
            opacity: inViewStates[2] ? 1 : 0,
            transition: 'transform 1s ease-out 0.6s, opacity 1s ease-out 0.6s',
          }}
        >
          <HoverCard
            title="Growth"
            description="10 weeks, remote program designed for startups looking to scale-up their business."
            cta="EXPLORE"
            backgroundColor="#FF6B6B" // Rojo vibrante
            image={bannerG}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Program;
