import React, { useEffect, useState, useRef } from 'react';
import { Box, Grid } from '@mui/material';
import { keyframes } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// Componentes personalizados
import MKTypography from 'DS/components/MKTypography';

// Imágenes
import bannerHome1 from 'assets/images/banners/banner-home1.jpg';
import bannerHome2 from 'assets/images/banners/banner-home2.jpg';
import bannerHome3 from 'assets/images/banners/banner-home3.jpg';
import bannerHome4 from 'assets/images/banners/bannerHome2.jpg';

// Definimos la animación de "flotación" para darle más vida a las imágenes
const float = keyframes`
  0% {
    transform: translateY(0px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  50% {
    transform: translateY(-10px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
  }
  100% {
    transform: translateY(0px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
`;

function Vision() {
  // Usamos el tema para adaptar el diseño al tamaño de la pantalla
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detectamos si es pantalla móvil

  // Estados para controlar la visibilidad de las imágenes (entrando y saliendo del viewport)
  const [isVisibleLeft, setIsVisibleLeft] = useState(false);
  const [isVisibleRight, setIsVisibleRight] = useState(false);

  const leftImageRef = useRef(null);
  const rightImageRef = useRef(null);

  // Estado para la posición del mouse
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });

  // Handle mouse movement for 3D effect
  const handleMouseMove = (e) => {
    if (isMobile) return; // Disable on mobile devices
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    const x = (e.clientX - left) / width;
    const y = (e.clientY - top) / height;

    const rotationX = (y - 0.5) * 20; // Ajusta el valor para controlar la intensidad
    const rotationY = (x - 0.5) * 20;

    setMousePos({ x: rotationX, y: rotationY });
  };

  // Reset mouse position when leaving the element
  const handleMouseLeave = () => {
    setMousePos({ x: 0, y: 0 });
  };

  // Detectamos cuando las imágenes entran o salen del viewport
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1, // Umbral para cuando el 10% del elemento sea visible
    };

    const leftObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisibleLeft(true); // Cuando entra al viewport
        } else {
          setIsVisibleLeft(false); // Cuando sale del viewport
        }
      });
    }, observerOptions);

    const rightObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisibleRight(true); // Cuando entra al viewport
        } else {
          setIsVisibleRight(false); // Cuando sale del viewport
        }
      });
    }, observerOptions);

    // Asignamos los observadores a los elementos
    if (leftImageRef.current) {
      leftObserver.observe(leftImageRef.current);
    }
    if (rightImageRef.current) {
      rightObserver.observe(rightImageRef.current);
    }

    return () => {
      if (leftImageRef.current) leftObserver.unobserve(leftImageRef.current);
      if (rightImageRef.current) rightObserver.unobserve(rightImageRef.current);
    };
  }, []);

  return (
    <section id="vision">
      <Box
        display="flex"
        position="relative"
        sx={{
          width: '100%',
          minHeight: '100vh',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          alignItems: { xs: 'center', md: 'flex-start' }, // Centrado en móvil, alineado en desktop
        }}
      >
        <Grid
          container={!isMobile} // Mostrar como contenedor si no es móvil
          spacing={4}
          justifyContent="center"
          alignItems="center"
          sx={{
            width: '100%',
            padding: { xs: 0, md: 5 },
            my: 5,
            mx: { xs: 5, md: 5 },
            alignItems: { xs: 'center', md: 'flex-start' },
          }}
        >
          {/* Imagen Izquierda (solo en pantallas grandes) */}
          <Grid item xs={12} md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
            <Box
              component="img"
              src={bannerHome2}
              alt="Lado Izquierdo"
              ref={leftImageRef}
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: 2,
                boxShadow: '0 8px 25px rgba(0, 0, 0, 0.3)', // Sombra para mayor profundidad
                animation: `${float} 2s ease-in-out infinite`, // Aplicamos la animación flotante
                transition: 'transform 1s ease, opacity 1s ease', // Transición para el desplazamiento
                transform: isVisibleLeft ? 'translateX(0)' : 'translateX(-100%)', // Entrar desde la izquierda
                opacity: isVisibleLeft ? 1 : 0, // Controlar opacidad
              }}
            />
          </Grid>

          {/* Contenedor de Texto y Imagen Inferior */}
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              sx={{
                perspective: '1000px', // Añade perspectiva para el efecto 3D
              }}
            >
              {/* Título principal */}
              <MKTypography variant="h1" color="text" sx={{ mb: 2 }}>
                <span
                  style={{
                    fontWeight: 'bold',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Sombra del texto
                  }}
                >
                  Why
                </span>{' '}
                we do it
              </MKTypography>

              {/* Texto descriptivo */}
              <Box
                sx={{
                  position: 'relative',
                  textAlign: 'center',
                  width: { xs: '100%', md: '80%' },
                  maxWidth: '800px',
                  fontSize: '1.2rem',
                  my: 2,
                }}
              >
                <MKTypography
                  color="text"
                  sx={{
                    position: 'relative',
                    zIndex: 1,
                    userSelect: 'none',
                  }}
                >
                  We envision a better world. A world where individuals are more connected with
                  themselves, others, and the world we live in. Our mission is to contribute towards
                  this vision and help make it a reality.
                </MKTypography>
              </Box>

              {/* Imagen Inferior con animación */}
              <Box
                component="img"
                src={bannerHome4}
                alt="Nuestra Visión"
                sx={{
                  width: '100%',
                  maxWidth: '400px',
                  height: 'auto',
                  marginTop: 4,
                  borderRadius: 2,
                  boxShadow: '0 8px 25px rgba(0, 0, 0, 0.3)', // Sombra para mayor profundidad
                  transition: 'transform 0.1s ease-out',
                  '&:hover': {
                    transform: `rotateX(${mousePos.x}deg) rotateY(${mousePos.y}deg)`,
                  },
                }}
              />
            </Box>
          </Grid>

          {/* Imagen Derecha (solo en pantallas grandes) */}
          <Grid item xs={12} md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
            <Box
              component="img"
              src={bannerHome3}
              alt="Lado Derecho"
              ref={rightImageRef}
              sx={{
                width: '100%',
                height: 'auto',
                borderRadius: 2,
                boxShadow: '0 8px 25px rgba(0, 0, 0, 0.3)', // Sombra para mayor profundidad
                animation: `${float} 3s ease-in-out infinite`, // Aplicamos la animación flotante
                transition: 'transform 1s ease, opacity 1s ease', // Transición para el desplazamiento
                transform: isVisibleRight ? 'translateX(0)' : 'translateX(100%)', // Salir hacia la derecha
                opacity: isVisibleRight ? 1 : 0, // Controlar opacidad
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </section>
  );
}

export default Vision;
