import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper';

import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';
import CustomContainer from 'DS/components/Container';

import { logosTech } from 'contexts/companies';

function Portfolio() {
  const theme = useTheme();

  // Estilos para cada logo de empresa
  const BoxLogo = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    width: '130px',
    height: '130px',
    boxShadow: 'none',
    borderRadius: '10px',
    transition: 'all 150ms ease-in',
    flexShrink: 0,
    mx: 1,
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.1)',
    },
  };

  return (
    <section id="portfolio">
      <MKBox display="flex" position="relative" id="PortfolioAcademy">
        <CustomContainer>
          {/* Título */}
          <MKBox py={10} textAlign="center">
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} lg={7} alignItems="center" flexDirection="column">
                <MKTypography
                  variant="h4"
                  align="center"
                  color="text"
                  sx={{
                    fontWeight: 400,
                    letterSpacing: 1,
                    marginBottom: 4,
                  }}
                >
                  <b>Who</b> we work with
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>

          {/* Carrusel con Swiper */}
          <Swiper
            modules={[Autoplay, FreeMode]}
            spaceBetween={90}
            slidesPerView="auto"
            freeMode={true}
            loop={true}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            speed={5000} // Ajusta este valor para cambiar la velocidad
            style={{ width: '100%' }}
          >
            {logosTech.map((item, index) => (
              <SwiperSlide key={index} style={{ width: 'auto' }}>
                <MKBox
                  sx={{
                    ...BoxLogo,
                  }}
                  onClick={() => window.open(item.url, '_blank')}
                >
                  {item.image ? (
                    <MKBox
                      component="img"
                      src={item.image}
                      alt={item.name}
                      sx={{
                        width: '150px',
                        height: '100%',
                        objectFit: 'contain',
                        p: '15px',
                      }}
                    />
                  ) : (
                    <MKTypography variant="h4" color="text" mb={1}>
                      {item.name}
                    </MKTypography>
                  )}
                </MKBox>
              </SwiperSlide>
            ))}
          </Swiper>
        </CustomContainer>
      </MKBox>
    </section>
  );
}

export default Portfolio;
