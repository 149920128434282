// src/components/Portfolio.js

import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

// Importar empresas desde el context
import { logosAcceleration, logosGrowth, logosIncubation } from 'contexts/companies';

const categories = ['Incubación', 'Aceleración', 'Growth'];

function Portfolio() {
  const [arrayIndex, setArrayIndex] = useState(1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const logosByCategory = [logosIncubation, logosAcceleration, logosGrowth];
  const currentLogos = logosByCategory[arrayIndex] || logosAcceleration;

  // Combinar los logos de todas las categorías para el carrusel
  const allLogos = [...logosAcceleration, ...logosIncubation, ...logosGrowth];
  // Estilos para cada logo de empresa
  const BoxLogo = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    width: '130px',
    height: '130px',
    boxShadow: 'none',
    borderRadius: '10px',
    transition: 'all 150ms ease-in',
    flexShrink: 0,
    '&:hover': {
      cursor: 'pointer',
      scale: 1.1,
    },
  };

  // Función para obtener la dirección del degradado ajustada por la rotación
  function getGradientDirection(index) {
    switch (index) {
      case 0: // Aceleración
        return '90deg'; // Degradado desde arriba (ajustado por la rotación)
      case 1: // Incubación
        return '230deg'; // Degradado desde la derecha
      case 2: // Growth
        return '360deg'; // Degradado desde abajo
      default:
        return '135deg';
    }
  }

  return (
    <section id="portfolio">
      <MKBox
        display="flex"
        position="relative"
        id="PortfolioAcademy"
        sx={{
          height: !isMobile ? '90vh' : 'auto',
          p: !isMobile ? 4 : 0,
        }}
      >
        <CustomContainer>
          {/* Título */}
          <MKBox py={3} textAlign={!isMobile ? 'start' : 'center'}>
            <Grid container spacing={1} justifyContent="start">
              <Grid item xs={12} lg={7} alignItems="start" flexDirection="column">
                <MKTypography
                  variant="h4"
                  align="start"
                  color="text"
                  sx={{
                    fontWeight: 400,
                    letterSpacing: 1,
                  }}
                >
                  <b>Our</b> portfolio
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>

          {/* Layout Responsivo */}
          {isMobile ? (
            <MKBox
              sx={{
                overflow: 'hidden',
                position: 'relative',
                width: '100%',
              }}
            >
              <MKBox
                sx={{
                  display: 'flex',
                  width: 'max-content',
                  animation: 'scroll 60s linear infinite',
                  '@keyframes scroll': {
                    '0%': { transform: 'translateX(0)' },
                    '100%': { transform: 'translateX(-50%)' },
                  },
                }}
              >
                {/* Duplicar los logos para crear el efecto de loop */}
                {[...allLogos, ...allLogos].map((item, index) => (
                  <MKBox
                    key={index}
                    sx={{
                      ...BoxLogo,
                    }}
                    onClick={() => window.open(item.url, '_blank')}
                  >
                    {item.image ? (
                      <MKBox
                        component="img"
                        src={item.image}
                        alt={item.name}
                        sx={{
                          width: '150px',
                          height: '100%',
                          objectFit: 'contain',
                          p: '15px',
                        }}
                      />
                    ) : (
                      <MKTypography variant="h4" color="text" mb={1}>
                        {item.name}
                      </MKTypography>
                    )}
                  </MKBox>
                ))}
              </MKBox>
            </MKBox>
          ) : (
            <MKBox display="flex" flexDirection="row" gap={4} alignItems="flex-start" height="70%">
              {/* Barra Vertical y Botones Verticales */}
              <MKBox
                display="flex"
                flexDirection="row"
                sx={{
                  alignItems: 'center',
                  gap: '8px',
                  transition: 'all 0.3s ease',
                  my: 'auto',
                  ml: -20,
                }}
              >
                {/* Barra Circular con Degradado */}
                <MKBox
                  sx={{
                    width: '200px',
                    height: '200px',
                    position: 'relative',
                    borderRadius: '50%',
                    transform: 'rotate(45deg)',
                    transition: 'all 3s ease',
                    backgroundColor: '#fff',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: '-10px',
                      left: '-10px',
                      width: '220px',
                      height: '220px',
                      borderRadius: '50%',
                      background: `linear-gradient(${getGradientDirection(arrayIndex)}, ${
                        theme.palette.primary.main
                      }, transparent)`,
                      mask: 'radial-gradient(closest-side, transparent calc(100% - 5px), black calc(100% - 10px))',
                      WebkitMask:
                        'radial-gradient(closest-side, transparent calc(100% - 15px), black calc(100% - 10px))',
                      zIndex: -1,
                      transition: 'background 2s ease',
                    },
                  }}
                />

                {/* Botones Verticales */}
                <MKBox
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  sx={{
                    justifyContent: 'start',
                  }}
                >
                  {categories.map((category, index) => (
                    <Box
                      key={index}
                      sx={{
                        width: '150px',
                        height: '50px',
                        borderRadius: '5px',
                        ml: category === 'Aceleración' ? 2 : 0,
                        textTransform: 'none',
                        justifyContent: 'left',
                        backgroundColor: 'transparent',
                        color:
                          arrayIndex === index
                            ? theme.palette.primary.main
                            : theme.palette.grey[600],
                        cursor: 'pointer',
                        transition: 'color 0.3s ease',
                        '&:hover': {
                          color: theme.palette.primary.main,
                        },
                      }}
                      onClick={() => setArrayIndex(index)}
                    >
                      {category}
                    </Box>
                  ))}
                </MKBox>
              </MKBox>

              {/* Listado de Empresas en el Lado Derecho */}
              <MKBox display="flex" flexWrap="wrap" gap={3} justifyContent="flex-start" my="auto">
                {currentLogos.map((item, index) => (
                  <MKBox
                    key={index}
                    sx={{
                      ...BoxLogo,
                    }}
                    onClick={() => window.open(item.url, '_blank')}
                  >
                    {item.image ? (
                      <MKBox
                        component="img"
                        src={item.image}
                        alt={item.name}
                        sx={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                          p: '15px',
                        }}
                      />
                    ) : (
                      <MKTypography variant="h4" color="text" mb={1}>
                        {item.name}
                      </MKTypography>
                    )}
                  </MKBox>
                ))}
              </MKBox>
            </MKBox>
          )}
        </CustomContainer>
      </MKBox>
    </section>
  );
}

export default Portfolio;
