// @mui icons
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Instagram from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';

// Material Kit 2 PRO React components
import MKTypography from 'DS/components/MKTypography';

// Images
import logoCT from 'assets/images/logos/Koi_black.png';

const date = new Date().getFullYear();

export default {
  brand: {
    name: 'Koi',
    image: logoCT,
    description: 'A place for extraordinary entrepeneurs to build innovative solutions',
    route: '/home',
  },
  socials: [
    {
      icon: <LinkedInIcon />,
      text: 'LinkedIn',
      link: 'https://www.linkedin.com/company/koiventures/',
    },
    {
      icon: <Instagram />,
      text: 'Instagram',
      link: 'https://www.instagram.com/koiventures.tech/',
    },
    // {
    //   icon: <YouTubeIcon />,
    //   link: 'https://www.youtube.com/@koiventures/',
    // },
  ],
  contact: [
    {
      icon: <EmailOutlinedIcon />,
      text: 'scouting@koigroup.io',
      link: 'mailto:scouting@koigroup.io',
    },

    {
      icon: <PlaceOutlinedIcon />,
      text: 'Buenos Aires',
    },
  ],
  menus: [
    {
      name: 'Navigation',
      items: [
        { name: 'Academy', href: '/academy' },
        { name: 'Ventures', href: '/ventures' },
        { name: 'Build', href: '/build' },
        { name: 'Tech', href: '/tech' },
      ],
    },
  ],
  submenus: [
    // {
    //   name: 'Terms & Conditions',
    //   href: '/aboutUs',
    // },
    { name: 'Privacy', href: '/privacy-policy' },
    { name: 'Cookie Settings', href: '/cookie-settings' },
  ],
  action: {
    text: 'We’d love to hear your idea',
    textLink: 'Chat with us',
    link: 'https://wa.me/+5491167161749?text=Hola Koi',
  },
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      <MKTypography
        component="a"
        href="https://koiventures.tech"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        {' '}
        &copy; KOI. All rights reserved {date}{' '}
      </MKTypography>
      .
    </MKTypography>
  ),
};
