import { useEffect, useState } from 'react';

// Componentes de Material-UI
import { useTheme } from '@emotion/react';
import { Grid } from '@mui/material';
import { keyframes } from '@mui/material';

// Componentes personalizados de Material Kit 2 PRO React
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

// Funciones auxiliares

// Imágenes (si son necesarias en el futuro)
import { Box } from '@mui/material';
import backgroundTech from 'assets/images/backgroundBuild.png';
import fishUp from 'assets/images/fish_down.png';

// Hook personalizado para manejar la coincidencia de consultas de medios
function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    const listener = () => setMatches(media.matches);
    media.addEventListener('change', listener);
    // Establecer el estado inicial
    setMatches(media.matches);
    return () => media.removeEventListener('change', listener);
  }, [query]);

  return matches;
}

const auroraAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

/**
 * Componente de Banner.
 */
function Banner() {
  const theme = useTheme();
  const [showFish, setShowFish] = useState(false);
  const isXs = useMediaQuery('(max-width: 720px)');

  const handleProgramsClick = () => {
    setShowFish(!showFish);
  };

  return (
    // banner tech
    <MKBox
      sx={{
        display: 'grid',
        placeItems: 'center',
        position: 'relative',
        overflow: 'hidden',
        backgroundImage: `url(${backgroundTech})`,
        backgroundSize: '150% 120%',
        animation: `${auroraAnimation} 10s ease-in-out infinite`,
        backgroundPosition: 'center',
        borderRadius: '0 0 60px 60px',
        px: { xs: 2, lg: '215px' },
        py: { xs: 0, lg: 18 },
        height: {
          xs: '90dvh',
          lg: 'auto',
        },
      }}
    >
      {/* Sección de encabezado con descripción */}
      <Grid container spacing={1} sx={{ px: { xs: 1, lg: 0 } }}>
        <Grid item xs={12} lg={12} sx={{ display: 'flex', flexDirection: 'row' }}>
          <Grid item xs={12} lg={12} sx={{ textAlign: 'start' }}>
            <MKTypography
              fontWeight="light"
              sx={{
                mr: 7,
                ml: 7,
                mt: '60px',
                fontSize: { xs: '1.8rem', sm: '55px' },
                lineHeight: { xs: '2.3rem', sm: '90px' }, // Ajustado para mejor legibilidad
                textAlign: 'center',
                color: '#000',
              }}
            >
              <Box
                component="span"
                sx={{
                  display: 'inline-block', // Permite transformaciones
                  color: '#000',
                  fontWeight: 'bold',
                  backgroundColor: '#fff',
                  padding: '0px 15px',
                  borderRadius: '10px',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  transform: 'rotate(1deg)', // Rotación ajustada a 10 grados
                  transition: 'all 0.8s ease', // Transición suave
                  zIndex: 10,
                  mb: '10px',
                  mt: '0px',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'rotate(-3deg)', // Rotación en hover
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0)',
                  },
                }}
                onClick={handleProgramsClick}
              >
                Our laboratory
              </Box>{' '}
              <br />
              The place where we create visionary ideas for impactful problems
            </MKTypography>
          </Grid>
        </Grid>
      </Grid>
    </MKBox>
  );
}

export default Banner;
